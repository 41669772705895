import { css, Global } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"
import React from "react"
import Theme from "./src/themes/theme"

const GlobalStyles = css`
  * {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body,
  html {
    font-family: ${Theme.fonts.body};
    height: 100%;
    color: white;
    background-color: ${Theme.colors.black};
  }

  a {
    text-decoration: none;
  }
  ol,
  ul {
    // list-style: none;
    font-size: 1.25rem;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }
  button,
  select {
    text-transform: none;
  }
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  button {
    background: none;
    padding: 0;
    border: none;
  }
`

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={Theme}>
    <Global styles={GlobalStyles} />
    {element}
  </ThemeProvider>
)
