export default {
  fonts: {
    body: "roboto, sans-serif",
    heading: "itc-avant-garde-gothic-pro, sans-serif",
    button: "industry-inc-base, sans-serif",
  },
  colors: {
    black: "#111315", //body background
    white: "#ffffff",
    gray1: "#7E7E7E", //sub-headings
    gray2: "#191919", //footer background
    gray3: "#313131", //start a project dropdown
    purple: "#581EFF", //strikethrough text color
  },
  breakpoints: {
    small: "only screen and (min-width: 40rem)", //mobile 640px
    medium: "only screen and (min-width: 46.25rem)", //tablet 768px 740px
    large: "only screen and (min-width: 64rem)", //desktop 1024px
    extralarge: "only screen and (min-width: 80rem)", //extra large desktop 1280px
  },
  spacings: {
    xxSmall: ".25rem",
    xSmall: ".5rem",
    small: "1rem",
    medium: "2rem",
    large: "3rem",
    xLarge: "4rem",
    xxLarge: "6rem",
  },
}
